<template>
  <div>
        <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">
           {{$t("questions.questiontitle")}}
          </h2>
          <b-card-text class="mb-2">
            {{$t("questions.questionsubtitle")}}
          </b-card-text>
        </b-card-body>
      </b-card>
    </section>
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >
        <b-tab
          v-for="(categoryObj, index) in faqData"
          :key="index"
        >

          <template #title>
            <feather-icon
              :icon="categoryObj.icon"
              size="18"
              class="mr-1"
            />
            <span class="font-weight-bold">{{ categoryObj.title }}</span>
          </template>

          <faq-question-answer :options="categoryObj" />
        </b-tab>
      </b-tabs>
    </section>
    <!--section class="faq-contact">
      <b-row class="mt-0 pt-75 text-center">
        <b-col cols="12">
          <h2>{{$t("questions.questionstill")}}</h2>
          <b-card-text class="mb-3">
             {{$t("questions.questionstillsub")}}
          </b-card-text>
        </b-col>
        <b-col sm="12">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="PhoneCallIcon"
                size="18"
              />
            </b-avatar>
            <h4>+(504) 8751-8741</h4>
            <span class="text-body">{{$t("questions.questionsw")}}</span>
          </b-card>
        </b-col>
      </b-row>
    </section-->
  </div>
</template>

<script>
import {
  BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BTabs, BTab, BImg, BRow, BCol, BAvatar,
} from 'bootstrap-vue'
import FaqQuestionAnswer from './FaqQuestionAnswer.vue'

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    FaqQuestionAnswer,
  },
  data() {
    return {
      faqData: [
          {
         title:'What are Elite Invitations?',subtitle:'Elite invitations details',icon:'AlertCircleIcon',
          qandA:[
            {question:'What is an Elite Invitation?',
            ans:'Elite Invitations are an Airdrop we sent out to 19 Solana NFT Communities. Communities who are Builders of Brands that have established themselves as leaders in the Solana NFT Ecosystem. They are NOT WHITELIST. You need to upgrade your Elite Invitation to a Whitelist Spot via our Elite Rewards Software.' },
            {question:'What is the Utility of Elite Emperors?',
            ans:'Every Elite Emperor will generate $Jewel Tokens which will be redeemable 1:1 for our IDO token $EL8 upon launch. 20% of our IDO will be allocated to Elite Emperor Holders.' },
            {question:'What is the plan with Elite Analytics? ',
            ans:'Sell Data Infrastructure and Analytics solutions to B2B. Specially Institutions, Protocols, DAOs, Regulators' },
             {question:'What NFT Projects received the Airdrop?',
            ans:"Aurory, Smyths, Catalina Whales, Cets on Creck, Communi3, Degen Ape Academy, Degods, Famous Fox Federation, Galactic Geckos, Genesis Genopets, GhostKid DAO, Okay Bears, Shadowy Super Coders, SMB, SolGods, Stoned Ape Crew, Taiyo Robotics, Trippin Ape Tribe, Turtles.<br>If you received an Elite Invitation, go to our discord channel: #🎁┃elite-invitation<br> If you bought an Elite Invitation, go to our discord channel: # 🎁┃p-g-invitation<br> If you didn't receive an Elite Invitation but were holding on of those 19 NFT Projects Check if you apply to our Elite Waitlist, we will give you an Invitation if you apply to:<a href='url'> https://waitlist.eliteemperors.com/</a> " },
          {question:'If I bought my Elite Invitation, do I get the same amount of XP as if I had received it?',
          ans:' No, you will get 50% of the XP on any crown.' },
            {question:"I don't want to grind for WL, this is dead! ",
          ans:"We get it; however, we need to validate your intent and interest in the project somehow. We don't want to ask you to connect to any dAPP or ask you to write gibberish on a channel. We have made the quests really easy for you." },
            {question:"I completed the quests and upgraded my crown to a whitelist spot; Will I receive something? ",
          ans:"Once you have upgraded your crown to a whitelist spot, you will have to wait 12-24 hours for the purchase to be approve. After the approval, your address will automatically add to the list of whitelisted address." },
        
            ]},
       
  
      ],
    }
  },

  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
